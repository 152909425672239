import { Box, Heading, TabContainer, Table, TableBody, TableData, TableRow, TabSection, themes, } from '@digitools/honest-react';
import useDentalDataContext from 'src/packages/dental/hooks/useDentalContext';
import DentalText from 'src/packages/dental/constants/DentalText';
import useTranslate from 'src/hooks/useTranslate';
import { TEALIUM_EVENT } from 'src/constants/tealiumConstants';
import { DEDUCTIBLES_AND_COINSURANCE, getTabs, tealiumFormat, } from 'src/packages/dental/providers/DentalDataProvider';
import React from 'react';
import { DeductiblesAndCoinsuranceHeader } from 'src/packages/dental/pages/DeductibleAndCoinsurance';
const PlanDeductiblesTableData = ({ plans, networkType }) => {
    return (<Table className={'mt-0 pt-0 mb-1'}>
      <TableBody>
        {plans &&
            plans.map((plan) => plan[networkType] && (<TableRow key={plan.coverageType} className={'border-bottom'} isHoverable={false}>
                  <TableData className={'border-0'}>
                    <Heading elemType={'h4'}>{plan.coverageType}</Heading>
                  </TableData>
                  <TableData className={'border-0 text-right font-weight-light'}>
                    {plan[networkType].networkDeductible}
                  </TableData>
                </TableRow>))}
      </TableBody>
    </Table>);
};
const PlanDeductibles = () => {
    const { currentMember, tealiumFunction } = useDentalDataContext();
    const { t } = useTranslate();
    const [inNetworkLabel, outNetworkLabel, deductiblesAndCoinsurances, planDeductibles] = getTabs('planDeductibles', currentMember);
    const hasData = !!(planDeductibles && planDeductibles?.length);
    const hasMultiTabs = !!(inNetworkLabel.length && outNetworkLabel.length);
    const deductiblesHeader = `${deductiblesAndCoinsurances?.deductibleType || ''} ${t(DentalText.deductiblesText)}`;
    const trackDropdownEvent = (eventName) => tealiumFunction({
        hasEventView: false,
        eventAction: TEALIUM_EVENT.EVENT_ACTION.TAB,
        eventName: tealiumFormat(`${eventName}-${tealiumFormat(deductiblesHeader)}`),
        eventType: tealiumFormat(DEDUCTIBLES_AND_COINSURANCE),
    });
    return (<Box theme={themes.lincoln} className={'h-100 pb-0'}>
      {!!deductiblesAndCoinsurances && (<>
          <DeductiblesAndCoinsuranceHeader title={deductiblesHeader} text={currentMember?.deductiblesAndCoinsurance?.deductibleHelpText}/>
          {deductiblesAndCoinsurances.amountApplied != null && (<Table className={'pb-0 mb-0'}>
              <TableBody>
                <TableRow isHoverable={false}>
                  <TableData className={'border-0 pt-0'}>
                    <Heading elemType={'h4'}>{t(DentalText.deductibleAmountPaidText)}</Heading>
                  </TableData>
                  <TableData className={'border-0 text-right font-weight-light align-bottom'}>
                    {' '}
                    ${deductiblesAndCoinsurances.amountApplied}
                  </TableData>
                </TableRow>
              </TableBody>
            </Table>)}
          {hasData && hasMultiTabs ? (<TabContainer noVertical={true}>
              <TabSection onClick={() => trackDropdownEvent(inNetworkLabel)} label={inNetworkLabel} name={'planDeductiblesinNetworkTab'}>
                <PlanDeductiblesTableData plans={planDeductibles} networkType={'inNetwork'}/>
              </TabSection>
              <TabSection onClick={() => trackDropdownEvent(outNetworkLabel)} label={outNetworkLabel} name={'planDeductiblesOutNetworkTab'}>
                <PlanDeductiblesTableData plans={planDeductibles} networkType={'outNetwork'}/>
              </TabSection>
            </TabContainer>) : (hasData && (<PlanDeductiblesTableData plans={planDeductibles} networkType={inNetworkLabel ? 'inNetwork' : 'outNetwork'}/>))}
        </>)}
    </Box>);
};
export default PlanDeductibles;
